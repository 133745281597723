import { useContext } from "react";

import Context from "./Context";

export const isAdmin = session => session?.connection === "Admin";

const useSession = () => {
  const { session } = useContext(Context);

  return session;
};

export default useSession;
