import React, { useEffect } from "react";
import { faDonate, faEllipsisV, faFileInvoiceDollar, faUsers, faCog } from '@fortawesome/free-solid-svg-icons'
import { useLazyQuery } from "@apollo/client";
import { pathOr, isEmpty } from "ramda";

import Link from "../design/Link";
import Menu from "../../lib/Menu";
import AccessControlledLink from "../../lib/AccessControlledLink";
import ActiveSweepstakes from "./ActiveSweepstakes";
import { nonprofitSweepstakesQuery } from "./gql";
import * as UI from "./ui";

const NonprofitMenu = ({ nonprofit = {} }) => {
  const [ getNonprofitSweepstakesQuery, { data } ] = useLazyQuery(nonprofitSweepstakesQuery, {
    variables: {
      nonprofitId: nonprofit.id
    },
  });

  useEffect(() => {
    if(nonprofit.id) {
      getNonprofitSweepstakesQuery();
    }
  }, [ nonprofit.id, getNonprofitSweepstakesQuery ]);

  const sweepstakes = pathOr([], ["nonprofit", "sweepstakes"], data);
  const invoices = pathOr([], ["nonprofit", "invoices"], data);
  const activeSweepstakes = sweepstakes.filter(s => s.approved && s.prize.win === null);

  return (
    <Menu label={<UI.Label size="tiny" icon={faEllipsisV} data-intercom-target="Nonprofit Menu">{nonprofit.name}</UI.Label>}>
      <UI.Layout>
        <UI.Title>{nonprofit.name}</UI.Title>
        <UI.SweepstakesList>
          { activeSweepstakes.map(s => <ActiveSweepstakes key={s.id} sweepstakes={s} />)}
        </UI.SweepstakesList>
        <UI.Controls>
          <AccessControlledLink to="./" icon={faDonate} resource="sweepstakes">All Sweepstakes</AccessControlledLink>
          <AccessControlledLink to="./nonprofit/" icon={faUsers} resource="team">Team</AccessControlledLink>
          <AccessControlledLink to="./nonprofit/invoices" icon={faFileInvoiceDollar} resource="invoices" disabled={isEmpty(invoices)}>Manual Donation Invoices</AccessControlledLink>
          <Link to="./nonprofit/preferences" icon={faCog}>Preferences</Link>
        </UI.Controls>
      </UI.Layout>
    </Menu>
  );
};

export default NonprofitMenu;
