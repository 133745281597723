import React from "react";
import { Routes, Route } from "react-router-dom";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

import * as UI from "./ui";

const TOURS = [
  { id: 551686, path: "promo-codes"         },
  { id: 554787, path: "flash-contests"      },
  { id: 545827, path: "insights"            },
  { id: 564096, path: "thank-you-campaigns" },
  { id: 572747, path: "donations"           },
  { id: 572461, path: "site"                },
  { id: 572472, path: "plugin"              },
];

const TourButton = () => {
  return (
    <Routes>
      {TOURS.map(({ id, path }) => (
        <Route
          key={id}
          path={`/sweepstakes/:sweepstakesId/${path}`}
          element={
            <UI.TourButton intent="tour" size="tiny" icon={faCircleInfo} onClick={() => window.Intercom('startTour', id)}>
              Show Me How
            </UI.TourButton>
          }
        />
      ))}
    </Routes>
  );
}

export default TourButton;
