import { gql } from "@apollo/client";

const nonprofitFragment = gql`
  fragment NonprofitFragment on Nonprofit {
    id
    profile {
      id
      email
      phone
      website
      cause
      color
      description

      logo {
        cloudName
        publicId
        version
      }

      image {
        cloudName
        publicId
        version
        crop
        gravity
        background
      }

      newsletterWebsite
      privacyPolicyWebsite
    }

    address {
      line1
      line2
      city
      state
      postalCode
      country
    }

    paymentAccountConnected
    paymentAccountConnectionUrl
  }
`;


const sweepstakesFragment = gql`
  fragment SweepstakesFragment on Sweepstakes {
    id
    headline
    description
    prizeDetails
    announcement
    location
    threshold
    googleContainerId
    googleAnalyticsPropertyId
    googleAdsConversionId
    facebookPixelId
    beginsAt
    endsAt
    winningTicketDrawnAt
    approved
    timezone

    prize {
      id
      name
      cashAlternative
    }

    address {
      name
      line1 
      line2 
      city
      state
      postalCode
      country
    }

    nonprofit {
      ...NonprofitFragment
    }

    checklist {
      id
      prize
      sweepstakesDetails
      donationLevels
      slides
      copy
      schedule
      mailInAddress
      paymentAccount
      nonprofitBranding
      aboutNonprofit
      nonprofitContact
    }
  }
  ${nonprofitFragment}
`;

export const sweepstakesQuery = gql`
  query SweepstakesQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      ...SweepstakesFragment
      slides {
        id
        caption
        image {
          cloudName
          publicId
          version
          crop
          gravity
          background
        }
      }
      donationLevels {
        id
        level
        price
        rewardTickets
      }
      sponsors {
        id
        website
        logo {
          cloudName
          publicId
          version
        }
      }
    }
    donationLevelPresets {
      id
      price
      rewardTickets
    }
  }
  ${sweepstakesFragment}
`;

export const updateSweepstakesMutation = gql`
  mutation UpdateSweepstakesMutation($id: SweepstakesSlug!, $attributes: SweepstakesInput!){
    updateSweepstakes(id: $id, attributes: $attributes) {
      sweepstakes {
        ...SweepstakesFragment
      }
      error {
        code
        message
      }
    }
  }
  ${sweepstakesFragment}
`;

export const updateNonprofitMutation = gql`
  mutation UpdateNonprofitMutation($id: NonprofitSlug!, $attributes: NonprofitInput!){
    updateNonprofit(id: $id, attributes: $attributes) {
      nonprofit {
        ...NonprofitFragment
      }
    }
  }
  ${nonprofitFragment}
`;

export const setDonationLevelsMutation = gql`
  mutation SetDonationLevels($sweepstakesId: SweepstakesSlug!, $donationLevelPresetIds: [ID!]!) {
    setDonationLevels(sweepstakesId: $sweepstakesId, donationLevelPresetIds: $donationLevelPresetIds) {
      sweepstakes {
        id
        donationLevels {
          id
          level
          price
          rewardTickets
        }
        checklist {
          id
          donationLevels
        }
      }
      error {
        message
        code
      }
    }
  }
`;

export const requestSweepstakesApprovalMutation = gql`
  mutation RequestSweepstakesApproval($id: SweepstakesSlug!) {
    requestSweepstakesApproval(id: $id) {
      error {
        message
        code
      }
    }
  }
`;

export const deleteSweepstakesMutation = gql`
  mutation DeleteSweepstakes($id: SweepstakesSlug!) {
    deleteSweepstakes(id: $id) {
      sweepstakesId
      error {
        message
        code
      }
    }
  }
`;
