import React from "react";

import useSession, { isAdmin } from "../../lib/permissions/useSession";
import ExportButton from "../ExportButton";

import * as UI from "./ui";

const EntryExports = ({ sweepstakesId }) => {
  const session = useSession();

  return (
    <UI.Layout>
      <ExportButton url={`/exports/${sweepstakesId}/donor_entries.csv`} resource="entries">Donations List</ExportButton>
      {isAdmin(session) && (
        <ExportButton url={`/exports/${sweepstakesId}/free_entries.csv`} resource="entries">AMoE List</ExportButton>
      )}
    </UI.Layout>
  )
};

export default EntryExports;
