import React from "react";

import Context from "./Context";

const PermissionsProvider = ({ role, session, children }) => (
  <Context.Provider value={{ permissions: role, session }}>
    { children }
  </Context.Provider>
);

export default PermissionsProvider;
